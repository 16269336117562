<template>
  <div>
    <select
        class="custom-select custom-select-sm mb-2"
        :id="passedData.name + '_' + passedData.index"
        :placeholder=passedData.placeHolder
        v-model="newValue"
        @change="changeValue()">
      <option
          v-for="(option) in passedOptions"
          :key="option.value"
          :value="option.value">{{option.name}}</option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'textInputField',
  created: async function () {
    this.newValue = this.passedData.value;
  },
  data: function () {
    return {
      newValue: this.passedData.value,
      passedOptions: this.passedData.options
    }
  },
  methods: {
    changeValue: function() {
      if (this.passedData.value !== this.newValue) {
        let returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: this.newValue,
          placeHolder: this.passedData.placeHolder,
          value: this.passedData.value
        };

        this.$emit("input", returnData);
      }
    },
  },
  props: ['passedData'],
}

</script>
