<template>

  <div class="container">

    <div id="UserModal">
      <UserModal
          v-if="showDetailModal"
          :passedUser="selectedUser"
          :passedTitles="loanOfficerTitles"
          :passedUsers="allUsersExcluding(selectedUser.username)"
          @close="showDetailModal=false"
          @change="changeFromModal"
      />
    </div>

    <div id="confirmUserDeleteModal">
      <ConfirmModal v-if="showConfirmUserDeleteModal" @confirm="deleteUser(selectedUser)" @cancel="showConfirmUserDeleteModal = false">
        <!-- Set these slot data elements to customize the modal -->
        <h3 slot="header">Confirm Delete</h3>
        <p slot="body">
          Are you sure you want to delete the user record for {{selectedUser.firstName}} {{selectedUser.lastName}}?
        </p>
      </ConfirmModal>
    </div>

    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h5>User Administration</h5>
            <small>Double click for full detail</small>
          </div>
          <div class="col-4">
            <input id="filter" placeholder="Filter by Name or Email" type="text" class="form-control" v-model="searchTerm">
          </div>
          <div v-if="isAdmin" class="col-4" style="text-align:right;">
            <div class="col-auto custom-cursor-pointer mt-2" @click="addUser()">
              <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
              <span>Add User</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th class="th-lg" scope="col">First Name</th>
            <th class="th-lg" scope="col">Last Name</th>
            <th class="th-lg" scope="col">User Name</th>
            <th class="th-lg" scope="col">Title</th>
            <th scope="col">Email</th>
            <th scope="col">Phone #</th>
            <th scope="col">NMLS #</th>
            <th scope="col">Corp. NMLS #</th>
            <th scope="col">Jungo User ID</th>
            <th scope="col">Team Lead</th>
            <th scope="col">Admin</th>
            <th scope="col">Active</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(user, index) in filteredResult" :key="user.id" v-on:dblclick="showUserModal(user)">
            <td>
              <label class="custom-fg-dark">{{user.firstName}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{user.lastName}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{user.username}}</label>
            </td>
            <td>
              <SelectInputField
                v-if="isAdmin"
                :key="user.loanOfficerTitle"
                :passed-data="{name: 'loanOfficerTitle', index: index, value: user.loanOfficerTitle, options: loanOfficerTitles}"
                @input="changeFromComponent">
              </SelectInputField>
            </td>
            <td>
              <ReadonlyTextField
                v-if="isAdmin"
                :key="user.email"
                :passedData="{name: 'email', index: index, value: user.email}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{user.email}}</label>
            </td>
            <td>
              <ReadonlyTextField
                  v-if="isAdmin"
                  :key="user.phoneNumber"
                  :passedData="{name: 'phoneNumber', index: index, value: user.phoneNumber}"
                  @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{user.phoneNumber}}</label>
            </td>
            <td>
              <ReadonlyTextField
                  v-if="isAdmin"
                  :key="user.nmlsNumber"
                  :passedData="{name: 'nmlsNumber', index: index, value: user.nmlsNumber}"
                  @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{user.nmlsNumber}}</label>
            </td>
            <td>
              <ReadonlyTextField
                  v-if="isAdmin"
                  :key="user.corporateNmlsNumber"
                  :passedData="{name: 'corporateNmlsNumber', index: index, value: user.corporateNmlsNumber}"
                  @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{user.corporateNmlsNumber}}</label>
            </td>
            <td>
              <ReadonlyTextField
                  v-if="isAdmin"
                  :key="user.jungoUserId"
                  :passedData="{name: 'jungoUserId', index: index, value: user.jungoUserId}"
                  @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{user.jungoUserId}}</label>
            </td>
            <td>
              <SelectInputField
                  v-if="isAdmin"
                  :key="user.teamLeadId"
                  :passed-data="{name: 'teamLeadId', index: index, value: user.teamLeadId, options: allUsersExcluding(user.username)}"
                  @input="changeFromComponent">
              </SelectInputField>
            </td>
            <td>
              <BooleanInputField
                v-if="isAdmin"
                :key="user.admin"
                :passedData="{name: 'admin', index: index, value: user.admin}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(user.admin)}}</label>
            </td>
            <td>
              <BooleanInputField
                v-if="isAdmin"
                :key="user.active"
                :passedData="{name: 'active', index: index, value: user.active}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(user.active)}}</label>
            </td>
            <td class="text-right custom-cursor-pointer">
              <i v-if="isAdmin" class="fa fa-trash" aria-hidden="true" @click="confirmDeleteUser(user)" />
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'

import Store from '@/store'

import User from '@/services/UserService'

import ConfirmModal from '@/components/modals/ConfirmModal'
import UserModal from '@/components/modals/UserModal'

import BooleanInputField from "@/components/util/BooleanInputField";
import ReadonlyTextField from "@/components/util/ReadonlyTextField";
import SelectInputField from "@/components/util/SelectInputField";
import PickDetail from "@/services/PickDetailService";

export default {
  name: 'users',
  components: {
    BooleanInputField,
    SelectInputField,
    ConfirmModal,
    ReadonlyTextField,
    UserModal
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.users.filter(result => {
        return (
          result.firstName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.lastName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.username.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          ((result.email !== undefined)
                  ? result.email.toLowerCase().match(this.searchTerm.toLowerCase())
                  : false
          )
        );
      });
    },
  },
  created: async function () {
    this.loanOfficerTitles = await PickDetail.getPickListDetail('loanofficertitle');

    this.users = await User.getUserList();
  },
  data: function () {
    return {
      users: [],
      searchTerm: "",
      showConfirmUserDeleteModal: false,
      showDetailModal: false,
      selectedUser: {},
      loanOfficerTitles: []
    }
  },
  methods: {
     getRandomInt: function(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },
    allUsersExcluding(username) {
       let result = [];

       result = this.users
           .filter(u => u.username !== username)
           .map(u => {
                 return {
                   value: u.id,
                   name: u.firstName + " " + u.lastName + ' (' + u.username + ')'
                 }
               }
           )

      result.unshift({
        value: null,
        name: 'NONE'
      });

      return result;
    },
    addUser: async function() {
      let user = {
        firstName: "New",
        lastName: "User",
        username: "newuser" + this.getRandomInt(1, 9999),
        password: "_raterabbit",
        email: null,
        phoneNumber: "",
        nmlsNumber: "",
        corporateNmlsNumber: "",
        admin: false,
        active: false
      }

      user = await User.createUser(user);
      this.users.push(user);
    },
    changeFromComponent: async function(returnData) {
      var name = returnData.name;
      var idx = returnData.index;
      var user = this.users[idx];
      var newValue = returnData.newValue;

      user[name] = newValue;
      this.users[idx] = await User.updateUser(user);
    },
    changeFromModal: async function(user) {
      var idx = lodash.findIndex(this.users, {id: user.id});
      this.users[idx] = await User.updateUser(user);
    },
    confirmDeleteUser: function(user) {
      this.selectedUser = user;
      this.showConfirmUserDeleteModal = true;
    },
    deleteUser: async function(user) {
      // Lodash's without was acting up with the modal interaction; doing it the more manual way
      var idx = lodash.findIndex(this.users, {id: user.id});

      this.users.splice(idx, 1);

      // Call the end point to delete from the database
      await User.deleteUser(user.id);

      this.showConfirmUserDeleteModal = false;
    },
    showUserModal(user) {
      this.selectedUser = user;
      this.showDetailModal = true;
    }
  }
}
</script>
