<template>
  <transition name="modal">
    <div class="modal-mask">
    <div class="modal-wrapper">
    <div class="modal-container">

    <div class="modal-header font-weight-bold">
      <slot name="header">User Detail for {{passedUser.firstName}} {{passedUser.lastName}}</slot>
    </div>

    <div class="modal-body mt-0 mb-0">
      <form>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="firstName" class="text-dark">First Name</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'firstName', index: 0, value: passedUser.firstName}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.firstName}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="lastName" class="text-dark">Last Name</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'lastName', index: 0, value: passedUser.lastName}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.lastName}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="username" class="text-dark">User Name</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'username', index: 0, value: passedUser.username}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.username}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="loanOfficerTitle" class="text-dark">Loan Officer Title</label>
          </div>
          <div class="form-group col-md-8">
            <SelectInputField
                v-if="isAdmin"
                :key="user.loanOfficerTitle"
                :passed-data="{name: 'loanOfficerTitle', index: index, value: user.loanOfficerTitle, options: passedTitles}"
                @input="changeFromComponent">
            </SelectInputField>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="email" class="text-dark">Email</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'email', index: 0, value: passedUser.email}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.email}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="phoneNumber" class="text-dark">Phone Number</label>
          </div>
          <div class="form-group col-md-8">
            <PhoneInputField v-if="isAdmin" :passedData="{name: 'phoneNumber', index: 0, value: passedUser.phoneNumber}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.phoneNumber}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="nmlsNumber" class="text-dark">NMLS #</label>
          </div>
          <div class="form-group col-md-8">
            <TextInputField v-if="isAdmin" :passedData="{name: 'nmlsNumber', index: 0, value: passedUser.nmlsNumber}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.nmlsNumber}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="corporateNmlsNumber" class="text-dark">Corporate NMLS #</label>
          </div>
          <div class="form-group col-md-8">
            <TextInputField v-if="isAdmin" :passedData="{name: 'corporateNmlsNumber', index: 0, value: passedUser.corporateNmlsNumber}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.corporateNmlsNumber}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="jungoUserId" class="text-dark">Jungo User ID</label>
          </div>
          <div class="form-group col-md-8">
            <TextInputField v-if="isAdmin" :passedData="{name: 'jungoUserId', index: 0, value: passedUser.jungoUserId}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.jungoUserId}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="teamLeadId" class="text-dark">Team Lead</label>
          </div>
          <div class="form-group col-md-8">
            <SelectInputField
                v-if="isAdmin"
                :key="user.teamLeadId"
                :passed-data="{name: 'teamLeadId', index: index, value: user.teamLeadId, options: passedUsers}"
                @input="changeFromComponent">
            </SelectInputField>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="admin" class="text-dark">Admin</label>
          </div>
          <div class="form-group col-md-3">
            <BooleanInputField v-if="isAdmin" :passedData="{name: 'admin', index: 0, value: passedUser.admin}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{(passedUser.admin) ? "Yes" : "No"}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="active" class="text-dark">Active</label>
          </div>
          <div class="form-group col-md-3">
            <BooleanInputField v-if="isAdmin" :passedData="{name: 'active', index: 0, value: passedUser.active}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{(passedUser.active) ? "Yes" : "No"}}</label>
          </div>
        </div>

        <div>
          <b-card bg-variant="light">
            <b-form-group
                id="password-group"
                label-align="left"
                description="Keep blank of you don't want to change password"
                label="Change password"
                label-for="password"
            >
              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-4 text-right">
                  <label for="password" v-if="isPasswordConfirmationNoteHidden" class="text-dark">New password</label>
                  <label for="password" v-if="!isPasswordConfirmationNoteHidden" class="text-danger">New password (!)</label>
                </div>
                <div class="form-group col-md-8">
                  <PasswordInputField v-if="isAdmin" :passedData="{name: 'password', index: 0, value: passedUser.password}" @input="changeFromComponent"/>
                  <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.password}}</label>
                </div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-4 text-right">
                  <label for="confirmPassword" v-if="isPasswordConfirmationNoteHidden" class="text-dark">Confirm password</label>
                  <label for="confirmPassword" v-if="!isPasswordConfirmationNoteHidden" class="text-danger">Confirm password (!)</label>
                </div>
                <div class="form-group col-md-8">
                  <PasswordInputField v-if="isAdmin" :passedData="{name: 'confirmPassword', index: 0, value: passedUser.confirmPassword}" @input="changeFromComponent"/>
                  <label v-if="!isAdmin" class="custom-fg-dark">{{passedUser.confirmPassword}}</label>
                </div>
              </div>
            </b-form-group>
          </b-card>
        </div>

      </form>
    </div>

    <div class="modal-footer">

      <div class="col-auto">
        <div class="container mt-2">
          <div class="row">
            <div class="col">
              <button class="btn btn-primary mt-2" @click.prevent="save()">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="container mt-2">
          <div class="row">
            <div class="col">
              <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
    </div>
    </div>
  </transition>
</template>

<script>
import BooleanInputField from '@/components/util/BooleanInputField'
import PasswordInputField from '@/components/util/PasswordInputField'
import TextInputField from '@/components/util/TextInputField'
import SelectInputField from "@/components/util/SelectInputField";
import PhoneInputField from "@/components/util/PhoneInputField";

import Store from '@/store'

export default {
  name: 'userModal',
  components: {
    BooleanInputField,
    PasswordInputField,
    TextInputField,
    SelectInputField,
    PhoneInputField
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    }
  },
  created: async function () {
    Object.assign(this.user, this.passedUser);
  },
  data: function () {
    return {
      user: {},
      isPasswordConfirmationNoteHidden: true
    }
  },
  methods: {
    changeFromComponent: function(returnData) {
      let name = returnData.name;
      let newValue = returnData.newValue;

      this.user[name] = newValue;
    },
    close: function () {
      this.$emit('close');
    },
    save: function () {
      if (this.user.password && this.user.confirmPassword !== this.user.password) {
        this.isPasswordConfirmationNoteHidden = false;
      }
      else {
        this.isPasswordConfirmationNoteHidden = true;

        Object.assign(this.passedUser, this.user);

        this.$emit('change', this.user);
        this.$emit('close');
      }
    },
  },
  props: ['passedUser', 'passedTitles', 'passedUsers'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 900px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
