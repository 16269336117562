<template>
  <div>
    <input
        type="text"
        class="form-control form-control-sm"
        :id="passedData.name + '_' + passedData.index"
        :placeholder=passedData.placeHolder
        v-model="newValue"
        readonly="true"
    />
  </div>
</template>

<script>
export default {
  name: 'textInputField',
  created: async function () {
    this.newValue = this.passedData.value;
  },
  data: function () {
    return {
      newValue: this.passedData.value,
    }
  },
  methods: {},
  props: ['passedData'],
}
</script>
