<template>
  <div>
    <input
      type="text"
      class="form-control form-control-sm"
      :id="passedData.name + '_' + passedData.index"
      :placeholder=passedData.placeHolder
      v-model="newValue"
      @change="changeValue()"
      @keyup="formatPhone()"
    />
  </div>
</template>

<script>

export default {
  name: 'textInputField',
  created: async function () {
    this.newValue = this.passedData.value;
  },
  data: function () {
    return {
      newValue: this.passedData.value,
    }
  },
  methods: {
    changeValue: function() {
      if (this.passedData.value != this.newValue) {
        var returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: this.newValue,
          placeHolder: this.passedData.placeHolder,
          value: this.passedData.value
        };

        this.$emit("input", returnData);
      }
    },
    formatPhone: function() {
      var value = this.newValue;

      //
      // Strip all characters from the input except digits and trim to ten characters
      //
      value = value.replace(/\D/g,'');
      value = value.substring(0,10);

      //
      // Format based on the length of the string
      //
      var size = value.length;

      if (size === 0) {
        // NOP
      } else if (size < 4) {
        value = '(' + value;
      } else if (size < 7) {
        value = '(' + value.substring(0,3) + ') ' + value.substring(3,6);
      } else {
        value = '(' + value.substring(0,3) + ') ' + value.substring(3,6) + '-' + value.substring(6,10);
      }

      this.newValue = value;
    },
  },
  props: ['passedData'],
}

</script>
